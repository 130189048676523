/* eslint-disable no-console */
export const state = () => ({
  documents: [],
  totalElements: 1,
  currentPage: 1,
  documentRecord: {},
  types: [],
  searchText: '',
  searchPayload: {
    tenant_id: '',
    organisation_id: '',
    dataset_id: [],
    user_id: '',
    user_email: '',
    types: [],
    is_relevant: [true],
    is_flagged: [],
    is_processed: [],
    types_supervised: [],
    not_processable: []
  },
  filterActive: false,
  associatedForms: [],
  nextAllowed: true,
  previousAllowed: false,
  firstRecord: {},
  lastRecord: {}
})

export const getters = {
  getDocuments(state) {
    return state.documents
  },
  getTotalElements(state) {
    return state.totalElements
  },
  getCurrentPage(state) {
    return state.currentPage
  },
  getTypes(state) {
    return state.types
  },
  getSearchText(state) {
    return state.searchText
  },
  getSearchPayload(state) {
    return state.searchPayload
  },
  getFilterActive(state) {
    return state.filterActive
  },
  getAssociatedForms(state) {
    return state.associatedForms
  },
  getDocumentRecord(state) {
    return state.documentRecord
  },
  getDocumentIsFlagged(state) {
    return state.documentIsFlagged
  },
  getNextAllowed(state) {
    return state.nextAllowed
  },
  getPreviousAllowed(state) {
    return state.previousAllowed
  },
  getFirstRecord(state) {
    return state.firstRecord
  },
  getLastRecord(state) {
    return state.lastRecord
  }
}

export const mutations = {
  setInvoices(state, invoices) {
    state.invoices = invoices
  },
  setInvoiceCount(state, count) {
    state.invoiceCount = count
  },

  // old setters
  setDocuments(state, documents) {
    state.documents = documents
  },
  setTotalElements(state, value) {
    state.totalElements = value
  },
  setCurrentPage(state, page) {
    state.currentPage = page
  },
  setTypes(state, type) {
    state.types = type
  },
  setSearchText(state, text) {
    state.searchText = text
  },
  setSearchPayload(state, payload) {
    state.searchPayload = payload
  },
  setFilterActive(state, bool) {
    state.filterActive = bool
  },
  setAssociatedForms(state, associatedForms) {
    state.associatedForms = associatedForms
  },
  setDocumentRecord(state, record) {
    state.documentRecord = record
  },
  setDocumentIsFlagged(state, isFlagged) {
    state.documentIsFlagged = isFlagged
  },
  setNextAllowed(state, next) {
    state.nextAllowed = next
  },
  setPreviousAllowed(state, previous) {
    state.previousAllowed = previous
  },
  setFirstRecord(state, record) {
    state.firstRecord = record
  },
  setLastRecord(state, record) {
    state.lastRecord = record
  }
}

export const actions = {
  async GET_DOCUMENTS({ commit, getters }) {
    try {
      const payload = JSON.parse(JSON.stringify(getters.getSearchPayload)) // deep copy
      const request = new URLSearchParams()
      request.append('page', getters.getCurrentPage)
      request.append('user_id', payload.user_id)
      // request.append('search', getters.getSearchText)
      payload.types.forEach((val) => request.append('types', val))
      request.append('dataset_id', payload.dataset_id)
      payload.is_relevant.forEach((val) => request.append('is_relevant', val))
      payload.is_flagged.forEach((val) => request.append('is_flagged', val))
      payload.is_processed.forEach((val) => request.append('is_processed', val))
      payload.types_supervised.forEach((val) =>
        request.append('types_supervised', val)
      )
      payload.not_processable.forEach((val) =>
        request.append('not_processable', val)
      )

      const response = await this.$axios.$get(`files`, {
        params: request
      })
      if (response !== null) {
        commit('setTotalElements', response.count)
        commit('setDocuments', response.results)
        if (getters.getDocumentRecord === {}) {
          commit('setDocumentRecord', response.results[0])
        }
      }
    } catch (error) {
      console.log('GET documents error: ' + error)
      return error
    }
  },
  async GET_TOTAL_ELEMENTS({ commit }) {
    try {
      const response = await this.$axios.$get('files')
      if (response !== null) {
        commit('setTotalElements', response.count)
      }
    } catch (error) {
      console.log('GET pagination error: ' + error.response)
      return error
    }
  },
  async GET_FIRST_LAST_ELEMENTS({ getters, commit }) {
    try {
      const payload = JSON.parse(JSON.stringify(getters.getSearchPayload))
      const request = new URLSearchParams()
      request.append('user_id', payload.user_id)
      // request.append('search', getters.getSearchText)
      payload.types.forEach((val) => request.append('types', val))
      request.append('dataset_id', payload.dataset_id)
      payload.is_relevant.forEach((val) => request.append('is_relevant', val))
      payload.is_flagged.forEach((val) => request.append('is_flagged', val))
      payload.is_processed.forEach((val) => request.append('is_processed', val))
      payload.types_supervised.forEach((val) =>
        request.append('types_supervised', val)
      )
      payload.not_processable.forEach((val) =>
        request.append('not_processable', val)
      )
      const totalPages = Math.ceil(getters.getTotalElements / 10)
      // if totalPages is 0 then there are no records to display and no need to make requests
      if (totalPages !== 0) {
        const responseFirstPage = await this.$axios.$get(`files?page=1`, {
          params: request
        })

        const responseLastPage = await this.$axios.$get(
          `files?page=${totalPages}`,
          {
            params: request
          }
        )
        commit('setFirstRecord', responseFirstPage.results[0])
        commit(
          'setLastRecord',
          responseLastPage.results[responseLastPage.results.length - 1]
        )
      }
    } catch (error) {
      console.log('error fetching the elements' + error)
      return error
    }
  },
  async GET_TYPES({ commit }) {
    try {
      const response = await this.$axios.$get('/document-types')
      if (response !== null) {
        commit('setTypes', response.results)
      }
    } catch (error) {
      console.log('GET Types error' + error)
      return error
    }
  },

  async UPDATE_RECORD(context, { record, key }) {
    try {
      await this.$axios.$patch(`files/${record.id}`, {
        [key]: record[key]
      })
    } catch (error) {
      console.log('GET Types error' + error)
      return error
    }
  },

  GET_NEXT_ELEMENT({ getters, commit, dispatch }) {
    const currentPage = getters.getCurrentPage
    const arrayLength = getters.getDocuments.length
    let record = getters.getDocumentRecord
    const index = getters.getDocuments
      .map((doc) => {
        return doc.id
      })
      .indexOf(record.id)
    if (index < arrayLength - 1) {
      record = getters.getDocuments[index + 1]
      commit('setNextAllowed', true)
      commit('setPreviousAllowed', true)
      if (
        index + 1 === arrayLength - 1 &&
        currentPage === Math.ceil(getters.getTotalElements / 10)
      ) {
        commit('setNextAllowed', false)
      }
    } else {
      commit('setCurrentPage', currentPage + 1)
      dispatch('GET_DOCUMENTS').then(() => {
        record = getters.getDocuments[0]
      })
    }
    commit('setDocumentRecord', record)
  },

  GET_PREVIOUS_ELEMENT({ getters, commit, dispatch }) {
    const currentPage = getters.getCurrentPage
    let record = getters.getDocumentRecord
    const index = getters.getDocuments
      .map((doc) => {
        return doc.id
      })
      .indexOf(record.id)
    if (index > 0) {
      record = getters.getDocuments[index - 1]
      commit('setNextAllowed', true)
      commit('setPreviousAllowed', true)
      if (index - 1 === 0 && currentPage === 1) {
        commit('setPreviousAllowed', false)
      }
    } else {
      commit('setCurrentPage', currentPage - 1)
      dispatch('GET_DOCUMENTS').then(() => {
        record = getters.getDocuments[9]
      })
    }
    commit('setDocumentRecord', record)
  },

  async GET_NEXT_PAGE({ getters, commit }) {
    try {
      const page = getters.getDocumentRecord
      if (page.next_page !== null) {
        const nextPage = await this.$axios.$get(
          `files/${page.next_page.substring(57)}`
        )
        commit('setDocumentRecord', nextPage)
      }
    } catch (error) {
      console.log('GET Types error' + error.response)
      return error.response
    }
  },

  async GET_PREVIOUS_PAGE({ getters, commit }) {
    try {
      const page = getters.getDocumentRecord
      if (page.previous_page !== null) {
        const previousPage = await this.$axios.$get(
          `files/${page.previous_page.substring(57)}`
        )
        commit('setDocumentRecord', previousPage)
      }
    } catch (error) {
      console.log('GET Types error' + error.response)
      return error.response
    }
  }

  // todo use this function to download document after finiding
  // todo a way to use global mixins in vuex store  (not being used atm)
  // async DOWNLOAD_DOCUMENT({ getters }) {
  //   await this.$axios({
  //     method: 'get',
  //     url: this.url,
  //     responseType: 'blob',
  //   })
  //     .then((response) => {
  //       // trying to access global mixin download
  //       this.download(response, this.currentInvoice.id)
  //       this.$message.success('Your document is getting downloaded')
  //     })
  //     .catch((error) => {
  //       this.$message.error('Issue with downloading the document')
  //       // eslint-disable-next-line no-console
  //       console.log('error occured', error)
  //     })
  // },
}
