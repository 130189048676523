export default {
  methods: {
    download(response, fileName) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.data.type })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)

      link.click()
      link.remove()
      window.URL.revokeObjectURL(url)
    },
  },
}
