
// globally adding mixins to the whole project where ever layout application is mentioned
import vue from 'vue'
import download from '~/mixins.js/download.js'
import arraySort from '~/mixins.js/object-sort.js'
vue.mixin(download)
vue.mixin(arraySort)
export default {
  // mounted() {
  //   // load context
  //   this.$store.dispatch('atomicEngine/GET_TENANTS') // todo static load
  //   this.$store.dispatch('atomicEngine/GET_DATASET') // todo satatic load
  //   // TODO: remove
  //   // this.$store.dispatch('atomicEngine/GET_OPTIONS')
  //   this.$store.dispatch('ledger/GET_OPTIONS')
  //   // load data
  //   this.$store.dispatch('invoices/GET_ALL_INVOICES') // todo move where it loads only once and load it on required basis
  //   this.$store.dispatch('invoices/GET_TYPES') // todo static load as per get total elements
  //   this.$store.dispatch('invoices/GET_COMPANIES') // todo static load as per get total elements
  // }
}
