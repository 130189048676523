/* eslint-disable no-console */
const pageSize = 100

export const state = () => ({
    companies: [],
    companiesCount: 0,
    filters: {
        incomplete: null,
        referenced_by_uncleared: null
    },
    currentPage: 1,
    currentCompany: {},
    searchArgument: null,
    companiesSimilarToCurrentCompany: [],
    allCompanies: [],
  })
  
  export const getters = {
    getCompanies(state) {
        return state.companies
    },
    getAllCompanies(state) {
      return state.allCompanies
  },
    getCurrentPage(state) {
      return state.currentPage
    },
    getCurrentCompany(state) {
      return state.currentCompany
    },
    getCompaniesSimilarToCurrentCompanySelection(state) {
      return state.companiesSimilarToCurrentCompany.map((c) => ({'value': c.id, 'text': c.name}))
    },
    getFilterParams(state) {
      const filterParams = new URLSearchParams()
      // Add general arguments
      filterParams.append('page', state.currentPage)
      if (state.searchArgument != null) filterParams.append('search', state.searchArgument)
      // Add specific filters
      if (state.filters.incomplete != null) filterParams.append('incomplete', state.filters.incomplete)
      if (state.filters.referenced_by_uncleared != null) filterParams.append('referenced_by_uncleared', state.filters.referenced_by_uncleared)
      return filterParams
    },
  }
  
  export const mutations = {
    setCompanies(state, results) {
      state.companies = results 
    },
    setCompaniesCount(state, count) {
      state.companiesCount = count
    },
    setFilters(state, payload) {
        if (payload.is_incomplete.length > 0) {state.filters.incomplete = payload.is_incomplete[0]} else state.filters.incomplete = null
        if (payload.is_referenced_by_uncleared_activities.length > 0) {state.filters.referenced_by_uncleared = payload.is_referenced_by_uncleared_activities[0]} else state.filters.referenced_by_uncleared = null
    },
    setCurrentPage(state, page) {
    state.currentPage = page
    },
    setCurrentCompany(state, response) {
      state.currentCompany = response
    },
    addCompany(state, company) {
      state.companies.push(company)
      state.allCompanies.push(company)
    },
    setSearchArgument(state, value) {
      state.searchArgument = value
    },
    setCompaniesSimilarToCurrentCompany(state, results) {
      state.companiesSimilarToCurrentCompany =  results.filter(function(c) { return c.id !== state.currentCompany.id; }); 

    },
    setAllCompanies(state, results) {
      state.allCompanies = results 
    },
  }
  
  export const actions = {
  
  
    async GET_COMPANIES({ commit, getters }) {
        try {
            const response = await this.$axios.$get(
            'companies?ordering=-created_at',
            {
                params: getters.getFilterParams
            }
            )
            if (response !== null) {
                commit('setCompanies', response.results)
                commit('setCompaniesCount', response.count)
            }
        }
        catch (error) {
            console.log('Error while fetching companies: ' + error)
            return error
        }
    },

    async GET_COMPANY_BY_ID({ state, getters, commit, dispatch }, id) {
      // if the invoice is in the current page of invoices, we just use that
      const response = await this.$axios.$get(
        `companies/${id}`
      )
      if (response !== null) {
        commit('setCurrentCompany', response)
        dispatch('GET_SIMILAR_COMPANIES_FOR_NAME',response.name)
      }
    },

    async GET_SIMILAR_COMPANIES_FOR_NAME({ state, getters, commit }, name) {
      // if the invoice is in the current page of invoices, we just use that
      const response = await this.$axios.$get(
        `companies?name__like=${name}`
      )
      if (response !== null) {
        commit('setCompaniesSimilarToCurrentCompany', response.results)
      }
    },
  
    
  
    async CREATE_NEW_COMPANY({ commit, getters }, company) {
      try {
        const response = await this.$axios.$post('companies',company)
      if (response !== null) {
        commit('addCompany',response)
        commit('setCurrentCompany',response)
        this._vm.$bvToast.toast('Company created sucessfully', {variant: 'success', title: 'Success'})
        return response
      }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.labels) {
          this._vm.$bvToast.toast(
            'Error from backend: ' + error.response.data.labels[0],
            {variant: 'danger', title: 'Could not create company'}
          )
        } else {
          this._vm.$bvToast.toast(
            'You are missing a field or the server is not responding.',
            {variant: 'danger', title: 'Could not create company'}
          )
        }

      }
    },

    async UPDATE_COMPANY({ commit, state }, company) {
      try {
        await this.$axios.$patch(`companies/${company.id}`, company)
        // Update company on current page
        const companies = [...state.companies]
          companies[
            companies
              .map((comp) => {
                return comp.id
              })
              .indexOf(company.id)
          ] = company
          commit('setCompanies', companies)
          // Update company in all companies list
          const allCompanies = [...state.allCompanies]
          allCompanies[
            allCompanies
              .map((comp) => {
                return comp.id
              })
              .indexOf(company.id)
          ] = company
          commit('setAllCompanies', allCompanies)
          this._vm.$bvToast.toast('Company updated sucessfully', {variant: 'success', title: 'Success'})
      } catch (error) {
        if (error.response && error.response.data && error.response.data.labels) {
          this._vm.$bvToast.toast(
            'Error from backend: ' + error.response.data.labels[0],
            {variant: 'danger', title: 'Could not update company'}
          )
        } else {
          this._vm.$bvToast.toast(
            'You are missing a field or the server is not responding.',
            {variant: 'danger', title: 'Could not update company'}
          )
        }
      }
    },

    async DELETE_COMPANY({ commit, state }, company) {
      try {
        await this.$axios.$delete(`companies/${company.id}`)
        // Remove from current page
        const companies = [...state.companies]
          const removeIndex1 = companies.findIndex(
            (comp) => comp.id === company.id
          )
          companies.splice(removeIndex1, 1)
          commit('setCompanies', companies)
          // Remove from all companies list
          const allCompanies = [...state.allCompanies]
          const removeIndex2 = allCompanies.findIndex(
            (comp) => comp.id === company.id
          )
          allCompanies.splice(removeIndex2, 1)
          commit('setAllCompanies', allCompanies)
          this._vm.$bvToast.toast('Company deleted sucessfully', {variant: 'success', title: 'Success'})
      } catch (error) {
        this._vm.$bvToast.toast(
          'The company could not be deleted. This is most likely a server issue.',
          {variant: 'danger', title: 'Could not delete company'}
        )
      }
    },

    // Get all companies to show them in the invoice data dropdown
    async GET_ALL_COMPANIES({ commit }) {
      try {
        let companies = []
        let i
        let response = await this.$axios.$get(
          `companies?incomplete=false&ordering=name&page_size=${pageSize}`
        )
        if (response !== null) {
          companies = [...response.results]
        }
        if (response.count > pageSize) {
          for (i = 2; i <= Math.ceil(response.count / pageSize); i++) {
            response = await this.$axios.$get(
              `companies?page=${i}&incomplete=false&ordering=name&page_size=${pageSize}`
            )
            companies = [...companies, ...response.results]
          }
        }
        if (companies.length > 0) {
          commit('setAllCompanies', companies)
        }
      } catch (error) {
        console.log('Problem while fetching all companies: ' + error)
        return error
      }
    },
  
  }