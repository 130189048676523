/* eslint-disable no-console */
const pageSize = 100

export const state = () => ({
  roles: [],
  reportingEntities: [],
  users: []
})

export const getters = {
  getUsers(state) {
    return state.users
  },
  getReportingEntities(state) {
    return state.reportingEntities
  }
}

export const mutations = {
  setReportingEntityRoles(state, roles) {
    state.roles = roles
  },
  setAllReportingEntities(state, entities) {
    state.reportingEntities = entities
  },
  setUsers(state, users) {
    state.users = users
  }
}

export const actions = {
  async FETCH_ALL_USERS({ state, commit, dispatch }) {
    try {
      let response = await this.$axios.$get(`/users?page_size=${pageSize}`)
      let users = [...response.results]
      for (let i = 2; i <= Math.ceil(response.count / pageSize); i++) {
        response = await this.$axios.$get(`/auth/users?page=${i}&page_size=${pageSize}`)
        users = [...users, ...response.results]
      }
      commit('setUsers', users)
    } catch (error) {
      throw await dispatch('GET_REQUEST_ERROR', error, { root: true })
    }
  },
  async FETCH_ROLES_REPORTING_ENTITY({ state, commit, dispatch }) {
    try {
      if (state.roles.length === 0)
        await this.$axios
          .$options('/reporting-entities')
          .then((res) =>
            commit('setReportingEntityRoles', res.actions.POST.role.choices)
          )
    } catch (error) {
      throw await dispatch('GET_REQUEST_ERROR', error, { root: true })
    }
  },
  async FETCH_ALL_ENTITES({ state, commit, dispatch }) {
    try {
      if (state.reportingEntities.length === 0) {
        let entities = []
        let i
        let response = await this.$axios.$get(`reporting-entities?page_size=${pageSize}`)
        entities = [...response.results]
        if (response.count > pageSize) {
          for (i = 2; i <= Math.ceil(response.count / pageSize); i++) {
            response = await this.$axios.$get(`reporting-entities?page=${i}&page_size=${pageSize}`)
            entities = [...entities, ...response.results]
          }
        }
        commit('setAllReportingEntities', entities)
      }
    } catch (error) {
      throw await dispatch('GET_REQUEST_ERROR', error, { root: true })
    }
  },
  async CREATE_REPORTING_ENTITY({ state, commit, dispatch }, entityPayload) {
    try {
      await this.$axios
        .$post(`/reporting-entities`, entityPayload)
        .then((res) => {
          dispatch('FETCH_ALL_ENTITES')
        })
    } catch (error) {
      throw await dispatch('GET_REQUEST_ERROR', error, { root: true })
    }
  },
  async RENAME_REPORTING_ENTITY({ state, commit, dispatch }, entityPayload) {
    const renamePatch = {
      name: entityPayload.name
    }
    try {
      await this.$axios
        .$patch(`/reporting-entities/${entityPayload.id}`, renamePatch)
        .then((_) => {
          dispatch('FETCH_ALL_ENTITES')
        })
    } catch (error) {
      throw await dispatch('GET_REQUEST_ERROR', error, { root: true })
    }
  },
  GET_REQUEST_ERROR(context, error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return error.request
    } else {
      // Something happened in setting up the request that triggered an Error
      return error.message
    }
  }
}
