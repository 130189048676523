/* eslint-disable no-console */
const pageSize = 100

export const state = () => ({
  tenants: [],
  dataset: [],
})

export const getters = {
  getTenants(state) {
    return state.tenants
  },
  getDataset(state) {
    return state.dataset
  },
}

export const mutations = {
  setTenants(state, tenants) {
    state.tenants = tenants
  },
  setDataset(state, dataset) {
    state.dataset = dataset
  },
}

export const actions = {
  async GET_TENANTS({ commit }) {
    try {
      let entities = []
      let i
      let response = await this.$axios.$get(`reporting-entities?page_size=${pageSize}`)
      entities = [...response.results]
      if (response.count > pageSize) {
        for (i = 2; i <= Math.ceil(response.count / pageSize); i++) {
          response = await this.$axios.$get(`reporting-entities?page=${i}&page_size=${pageSize}`)
          entities = [...entities, ...response.results]
        }
      }
      commit('setTenants', entities)
    } catch (error) {
      console.log('GET tenants fetching error: ' + error)
      return error
    }
  },
  async GET_DATASET({ commit, getters }) {
    try {
      let datasets = []
      let i
      let response = await this.$axios.$get(`datasets?page_size=${pageSize}`)
      datasets = [...response.results]
      if (response.count > pageSize) {
        for (i = 2; i <= Math.ceil(response.count / pageSize); i++) {
          response = await this.$axios.$get(`datasets?page=${i}&page_size=${pageSize}`)
          datasets = [...datasets, ...response.results]
        }
      }
      commit('setDataset', datasets)
    } catch (error) {
      console.log('GET dataset fetching error: ' + error)
      return error
    }
  },
}
