export const state = () => ({
  invoiceSets: [],
  invoiceSetsCount: 1,
  currentPage: 1,
  searchPayload: {
    tenant_id: '',
    dataset_id: '',
    is_finished: [false],
    is_empty: [false],
    is_demo_data: [false]
  },
  invoiceSetsPerDataset: []
})

export const getters = {
  getInvoiceSets(state) {
    return state.invoiceSets
  },
  getInvoiceSetsPerDataset(state) {
    return state.invoiceSetsPerDataset
  },
  getCurrentPage(state) {
    return state.currentPage
  },
  getFilterParams(state) {
    const filterParams = new URLSearchParams()
    const params = JSON.parse(JSON.stringify(state.searchPayload))
    filterParams.append('page', state.currentPage)
    filterParams.append('dataset_id', params.dataset_id)
    params.is_finished.forEach((val) => filterParams.append('is_finished', val))
    params.is_empty.forEach((val) => filterParams.append('is_empty', val))
    params.is_demo_data.forEach((val) => filterParams.append('is_demo_data', val))

    return filterParams
  }
}

export const mutations = {
  setInvoiceSets(state, invoiceSets) {
    state.invoiceSets = invoiceSets
  },
  setInvoiceSetsPerDataset(state, sets) {
    state.invoiceSetsPerDataset = sets
  },
  setInvoiceSetsCount(state, count) {
    state.invoiceSetsCount = count
  },
  setCurrentPage(state, page) {
    state.currentPage = page
  },
  setSearchPayload(state, payload) {
    state.searchPayload = payload
  }
}

export const actions = {
  async GET_INVOICE_SETS_FOR_DATASET({ rootState, commit }, datasetId) {
    try {
      let sets = []
      let i
      let response = await this.$axios.$get(
        `${process.env.BASE_V2_URL}invoice-sets?ordering=-created_at&dataset_id=${datasetId}`
      )
      sets = [...response.results]
      if (response.count > 10) {
        for (i = 2; i <= Math.ceil(response.count / 10); i++) {
          response = await this.$axios.$get(
            `${process.env.BASE_V2_URL}invoice-sets?ordering=-created_at&dataset_id=${datasetId}&page=${i}`
          )
          sets = [...sets, ...response.results]
        }
      }
      commit('setInvoiceSetsPerDataset', sets)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('GET invoice Sets error' + error)
      return error
    }
  },

  async GET_FILTERED_INVOICE_SETS({ commit, getters }) {
    try {
      const response = await this.$axios.$get(
        `${process.env.BASE_V2_URL}invoice-sets?ordering=-created_at`,
        {
          params: getters.getFilterParams
        }
      )
      if (response !== null) {
        commit('setInvoiceSets', response.results)
        commit('setInvoiceSetsCount', response.count)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('GET invoice sets filter error' + error)
      return error
    }
  },
  async UPDATE_INVOICE_SET(context, { record, key }) {
    try {
      await this.$axios.$patch(
        `${process.env.BASE_V2_URL}invoice-sets/${record.id}`,
        {
          [key]: record[key]
        }
      )
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('UPDATE Invoice set error' + error)
      return error
    }
  }
}
