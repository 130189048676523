/* eslint-disable no-console */
export const state = () => ({
  cashOptions: {},
  energyOptions: {},
  fuelOptions: {},
  goodsOptions: {},
  refrigerantOptions: {},
  wasteOptions: {},
  waterOptions: {},
  offsetOptions: {},
  accommodationOptions: {},
  transportOptions: {},
  travelOptions: {},
  commuteOptions: {},
  homeWorkOptions: {},
})

function compareByDisplayName(a, b) {
  if (a.display_name < b.display_name) {
    return -1
  }
  if (a.display_name > b.display_name) {
    return 1
  }
  return 0
}

export const getters = {
  // CASH
  getCashQuantityUnit(state) {
    return state.cashOptions.quantity.children.unit.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  getCashContexts(state) {
    return state.cashOptions.context.choices.slice(0).sort(compareByDisplayName)
  },
  getCashSectors(state) {
    return state.cashOptions?.sector?.choices
  },
  getCashOECDSectors(state) {
    return state.cashOptions.oecd_sector.choices
  },
  // ENERGY
  getEnergyTypes(state) {
    return state.energyOptions.type.choices.slice(0).sort(compareByDisplayName)
  },
  getEnergyQuantityUnit(state) {
    return state.energyOptions.quantity.children.unit.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  getEnergyMarketEmissionFactorUnit(state) {
    return state.energyOptions.market_based_co2e_emission_factor.children.unit.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  getEnergyLocations(state) {
    return state.energyOptions.location.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  getEnergyContexts(state) {
    return state.energyOptions.context.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  // FUEL
  getFuelTypes(state) {
    return state.fuelOptions.type.choices.slice(0).sort(compareByDisplayName)
  },
  getFuelQuantityUnit(state) {
    return state.fuelOptions.quantity.children.unit.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  getFuelContexts(state) {
    return state.fuelOptions.context.choices.slice(0).sort(compareByDisplayName)
  },
  // GOODS
  getGoodsTypes(state) {
    return state.goodsOptions.type.choices.slice(0).sort(compareByDisplayName)
  },
  getGoodsQuantityUnit(state) {
    return state.goodsOptions.quantity.children.unit.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  getGoodsContexts(state) {
    return state.goodsOptions.context.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  // REFRIGERANT
  getRefrigerantTypes(state) {
    return state.refrigerantOptions.type.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  getRefrigerantQuantityUnit(state) {
    return state.refrigerantOptions.quantity.children.unit.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  getRefrigerantContexts(state) {
    return state.refrigerantOptions.context.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  // WASTE
  getWasteTypes(state) {
    return state.wasteOptions.type.choices.slice(0).sort(compareByDisplayName)
  },
  getWasteQuantityUnit(state) {
    return state.wasteOptions.quantity.children.unit.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  getWasteContexts(state) {
    return state.wasteOptions.context.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  // WATER
  getWaterTypes(state) {
    return state.waterOptions.type.choices.slice(0).sort(compareByDisplayName)
  },
  getWaterQuantityUnit(state) {
    return state.waterOptions.quantity.children.unit.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  getWaterContexts(state) {
    return state.waterOptions.context.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  // OFFSETS
  getOffsetTypes(state) {
    return state.offsetOptions.type.choices.slice(0).sort(compareByDisplayName)
  },
  getOffsetQuantityUnit(state) {
    return state.offsetOptions.quantity.children.unit.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  getOffsetContexts(state) {
    return state.offsetOptions.context.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  // TRAVEL
  getTravelTypes(state) {
    return state.travelOptions.type.choices.slice(0).sort(compareByDisplayName)
  },
  getTravelQuantityUnit(state) {
    return state.travelOptions.quantity.children.unit.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  getTravelContexts(state) {
    return state.travelOptions.context.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  // TRANSPORT
  getTransportTypes(state) {
    return state.transportOptions.type.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  getTransportQuantityUnit(state) {
    return state.transportOptions.quantity.children.unit.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  getTransportContexts(state) {
    return state.transportOptions.context.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  // ACCOMMODATION
  getAccommodationTypes(state) {
    return state.accommodationOptions.type.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  getAccommodationQuantityUnit(state) {
    return state.accommodationOptions.quantity.children.unit.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  getAccommodationContexts(state) {
    return state.accommodationOptions.context.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  // COMMUTE
  getCommuteTypes(state) {
    return state.commuteOptions.type.choices.slice(0).sort(compareByDisplayName)
  },
  getCommuteQuantityUnit(state) {
    return state.commuteOptions.quantity.children.unit.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  // HOMEOFFICE
  getHomeOfficeTypes(state) {
    return state.homeOfficeOptions.type.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  getHomeOfficeQuantityUnit(state) {
    return state.homeOfficeOptions.quantity.children.unit.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
  getHomeOfficeContexts(state) {
    return state.homeOfficeOptions.context.choices
      .slice(0)
      .sort(compareByDisplayName)
  },
}

export const mutations = {
  setCashOptions(state, options) {
    state.cashOptions = options
  },
  setEnergyOptions(state, options) {
    state.energyOptions = options
  },
  setFuelOptions(state, options) {
    state.fuelOptions = options
  },
  setGoodsOptions(state, options) {
    state.goodsOptions = options
  },
  setRefrigerantOptions(state, options) {
    state.refrigerantOptions = options
  },
  setWasteOptions(state, options) {
    state.wasteOptions = options
  },
  setWaterOptions(state, options) {
    state.waterOptions = options
  },
  setOffsetOptions(state, options) {
    state.offsetOptions = options
  },
  setTravelOptions(state, options) {
    state.travelOptions = options
  },
  setTransportOptions(state, options) {
    state.transportOptions = options
  },
  setAccommodationOptions(state, options) {
    state.accommodationOptions = options
  },
  setCommuteOptions(state, options) {
    state.commuteOptions = options
  },
  setHomeOfficeOptions(state, options) {
    state.homeOfficeOptions = options
  },
}

export const actions = {
  async GET_CASH_OPTIONS({ commit, getters }) {
    try {
      const response = await this.$axios.$options(`/ledger/flows/cash`)
      commit('setCashOptions', response.actions.POST)
    } catch (error) {
      console.log('GET cash options fetching error: ' + error)
      return error
    }
  },
  async GET_ENERGY_OPTIONS({ commit, getters }) {
    try {
      const response = await this.$axios.$options(`/ledger/flows/energy`)
      commit('setEnergyOptions', response.actions.POST)
    } catch (error) {
      console.log('GET energy options fetching error: ' + error)
      return error
    }
  },
  async GET_FUEL_OPTIONS({ commit, getters }) {
    try {
      const response = await this.$axios.$options(`/ledger/flows/fuel`)
      commit('setFuelOptions', response.actions.POST)
    } catch (error) {
      console.log('GET fuel options fetching error: ' + error)
      return error
    }
  },
  async GET_GOODS_OPTIONS({ commit, getters }) {
    try {
      const response = await this.$axios.$options(`/ledger/flows/goods`)
      commit('setGoodsOptions', response.actions.POST)
    } catch (error) {
      console.log('GET goods options fetching error: ' + error)
      return error
    }
  },
  async GET_REFRIGERANT_OPTIONS({ commit, getters }) {
    try {
      const response = await this.$axios.$options(`/ledger/flows/refrigerant`)
      commit('setRefrigerantOptions', response.actions.POST)
    } catch (error) {
      console.log('GET refrigerant options fetching error: ' + error)
      return error
    }
  },
  async GET_WASTE_OPTIONS({ commit, getters }) {
    try {
      const response = await this.$axios.$options(`/ledger/flows/waste`)
      commit('setWasteOptions', response.actions.POST)
    } catch (error) {
      console.log('GET waste options fetching error: ' + error)
      return error
    }
  },
  async GET_WATER_OPTIONS({ commit, getters }) {
    try {
      const response = await this.$axios.$options(`/ledger/flows/water`)
      commit('setWaterOptions', response.actions.POST)
    } catch (error) {
      console.log('GET water options fetching error: ' + error)
      return error
    }
  },
  async GET_OFFSET_OPTIONS({ commit, getters }) {
    try {
      const response = await this.$axios.$options(`/ledger/flows/offsets`)
      commit('setOffsetOptions', response.actions.POST)
    } catch (error) {
      console.log('GET offset options fetching error: ' + error)
      return error
    }
  },
  async GET_TRAVEL_OPTIONS({ commit, getters }) {
    try {
      const response = await this.$axios.$options(`/ledger/processes/travel`)
      commit('setTravelOptions', response.actions.POST)
    } catch (error) {
      console.log('GET travel options fetching error: ' + error)
      return error
    }
  },
  async GET_TRANSPORT_OPTIONS({ commit, getters }) {
    try {
      const response = await this.$axios.$options(`/ledger/processes/transport`)
      commit('setTransportOptions', response.actions.POST)
    } catch (error) {
      console.log('GET transport options fetching error: ' + error)
      return error
    }
  },
  async GET_ACCOMMODATION_OPTIONS({ commit, getters }) {
    try {
      const response = await this.$axios.$options(
        `/ledger/processes/accommodation`
      )
      commit('setAccommodationOptions', response.actions.POST)
    } catch (error) {
      console.log('GET accommodation options fetching error: ' + error)
      return error
    }
  },
  async GET_COMMUTE_OPTIONS({ commit, getters }) {
    try {
      const response = await this.$axios.$options(`/ledger/processes/commute`)
      commit('setCommuteOptions', response.actions.POST)
    } catch (error) {
      console.log('GET commute options fetching error: ' + error)
      return error
    }
  },
  async GET_HOMEOFFICE_OPTIONS({ commit, getters }) {
    try {
      const response = await this.$axios.$options(
        `/ledger/processes/homeoffice`
      )
      commit('setHomeOfficeOptions', response.actions.POST)
    } catch (error) {
      console.log('GET homeoffice options fetching error: ' + error)
      return error
    }
  },
  async GET_OPTIONS({ dispatch }) {
    await dispatch('GET_CASH_OPTIONS')
    await dispatch('GET_ENERGY_OPTIONS')
    await dispatch('GET_FUEL_OPTIONS')
    await dispatch('GET_GOODS_OPTIONS')
    await dispatch('GET_REFRIGERANT_OPTIONS')
    await dispatch('GET_WASTE_OPTIONS')
    await dispatch('GET_WATER_OPTIONS')
    await dispatch('GET_OFFSET_OPTIONS')
    await dispatch('GET_TRAVEL_OPTIONS')
    await dispatch('GET_TRANSPORT_OPTIONS')
    await dispatch('GET_ACCOMMODATION_OPTIONS')
    await dispatch('GET_COMMUTE_OPTIONS')
    await dispatch('GET_HOMEOFFICE_OPTIONS')
  },

  UPDATE_CASH({ getters }, payload) {
    try {
      if ('id' in payload && payload.id) {
        return this.$axios.$patch(`/ledger/flows/cash/${payload.id}`, payload)
      } else {
        return this.$axios.$post(`/ledger/flows/cash`, payload)
      }
    } catch (error) {
      throw error.response
    }
  },
  UPDATE_ENERGY({ getters }, payload) {
    try {
      if ('id' in payload && payload.id) {
        return this.$axios.$patch(`/ledger/flows/energy/${payload.id}`, payload)
      } else {
        return this.$axios.$post(`/ledger/flows/energy`, payload)
      }
    } catch (error) {
      throw error.response
    }
  },
  UPDATE_FUEL({ getters }, payload) {
    try {
      if ('id' in payload && payload.id) {
        return this.$axios.$patch(`/ledger/flows/fuel/${payload.id}`, payload)
      } else {
        return this.$axios.$post(`/ledger/flows/fuel`, payload)
      }
    } catch (error) {
      throw error.response
    }
  },
  UPDATE_GOODS({ getters }, payload) {
    try {
      if ('id' in payload && payload.id) {
        return this.$axios.$patch(`/ledger/flows/goods/${payload.id}`, payload)
      } else {
        return this.$axios.$post(`/ledger/flows/goods`, payload)
      }
    } catch (error) {
      throw error.response
    }
  },
  UPDATE_REFRIGERANT({ getters }, payload) {
    try {
      if ('id' in payload && payload.id) {
        return this.$axios.$patch(
          `/ledger/flows/refrigerant/${payload.id}`,
          payload
        )
      } else {
        return this.$axios.$post(`/ledger/flows/refrigerant`, payload)
      }
    } catch (error) {
      throw error.response
    }
  },
  UPDATE_WASTE({ getters }, payload) {
    try {
      if ('id' in payload && payload.id) {
        return this.$axios.$patch(`/ledger/flows/waste/${payload.id}`, payload)
      } else {
        return this.$axios.$post(`/ledger/flows/waste`, payload)
      }
    } catch (error) {
      throw error.response
    }
  },
  UPDATE_WATER({ getters }, payload) {
    try {
      if ('id' in payload && payload.id) {
        return this.$axios.$patch(`/ledger/flows/water/${payload.id}`, payload)
      } else {
        return this.$axios.$post(`/ledger/flows/water`, payload)
      }
    } catch (error) {
      throw error.response
    }
  },
  UPDATE_OFFSET({ getters }, payload) {
    try {
      if ('id' in payload && payload.id) {
        return this.$axios.$patch(
          `/ledger/flows/offsets/${payload.id}`,
          payload
        )
      } else {
        return this.$axios.$post(`/ledger/flows/offsets`, payload)
      }
    } catch (error) {
      throw error.response
    }
  },
  UPDATE_TRAVEL({ getters }, payload) {
    try {
      if ('id' in payload && payload.id) {
        return this.$axios.$patch(
          `/ledger/processes/travel/${payload.id}`,
          payload
        )
      } else {
        return this.$axios.$post(`/ledger/processes/travel`, payload)
      }
    } catch (error) {
      throw error.response
    }
  },
  UPDATE_TRANSPORT({ getters }, payload) {
    try {
      if ('id' in payload && payload.id) {
        return this.$axios.$patch(
          `/ledger/processes/transport/${payload.id}`,
          payload
        )
      } else {
        return this.$axios.$post(`/ledger/processes/transport`, payload)
      }
    } catch (error) {
      throw error.response
    }
  },
  UPDATE_ACCOMMODATION({ getters }, payload) {
    try {
      if ('id' in payload && payload.id) {
        return this.$axios.$patch(
          `/ledger/processes/accommodation/${payload.id}`,
          payload
        )
      } else {
        return this.$axios.$post(`/ledger/processes/accommodation`, payload)
      }
    } catch (error) {
      throw error.response
    }
  },
  UPDATE_COMMUTE({ getters }, payload) {
    try {
      if ('id' in payload && payload.id) {
        return this.$axios.$patch(
          `/ledger/processes/commute/${payload.id}`,
          payload
        )
      } else {
        return this.$axios.$post(`/ledger/processes/commute`, payload)
      }
    } catch (error) {
      throw error.response
    }
  },
  UPDATE_HOMEOFFICE({ getters }, payload) {
    try {
      if ('id' in payload && payload.id) {
        return this.$axios.$patch(
          `/ledger/processes/homeoffice/${payload.id}`,
          payload
        )
      } else {
        return this.$axios.$post(`/ledger/processes/homeoffice`, payload)
      }
    } catch (error) {
      throw error.response
    }
  },
}
