/* eslint-disable no-console */
export const state = () => ({
  invoices: [],
  allInvoices: [],
  invoiceCount: 1,
  currentInvoice: {},
  currentInvoicePage: {},
  currentInvoicePages: [],
  invoicePageNumber: 1,
  currentPage: 1,
  types: [],
  searchText: '', // todo implement it later
  searchPayload: {
    tenant_id: '',
    dataset_id: '',
    invoice_set: '',
    user_id: '',
    user_email: '',
    types: [],
    is_relevant: [true],
    is_flagged: [],
    is_supervised: [],
    types_supervised: [],
    not_processable: [],
    is_ready: [true]
  },
  filterActive: false,
  associatedForms: [],
  nextAllowed: true,
  previousAllowed: false,
  currentDataSet: {},
  staffMessage: '',
  loadDataAsync: false,
})

export const getters = {
  getCurrentPage(state) {
    return state.currentPage
  },
  getTypes(state) {
    return state.types
  },
  getSearchText(state) {
    return state.searchText
  },
  getFilterActive(state) {
    return state.filterActive
  },
  getAssociatedForms(state) {
    return state.associatedForms
  },
  getDocumentIsFlagged(state) {
    return state.documentIsFlagged
  },
  getNextAllowed(state) {
    return state.nextAllowed
  },
  getPreviousAllowed(state) {
    return state.previousAllowed
  },
  getFirstRecord(state) {
    return state.firstRecord
  },
  getLastRecord(state) {
    return state.lastRecord
  },
  getCurrentInvoice(state) {
    return state.currentInvoice
  },
  getFilterParams(state) {
    const filterParams = new URLSearchParams()
    const params = JSON.parse(JSON.stringify(state.searchPayload))
    filterParams.append('page', state.currentPage)
    filterParams.append('dataset_id', params.dataset_id)
    filterParams.append('invoice_set', params.invoice_set)
    params.is_relevant.forEach((val) => filterParams.append('is_relevant', val))
    params.is_flagged.forEach((val) => filterParams.append('is_flagged', val))
    params.is_supervised.forEach((val) =>
      filterParams.append('is_supervised', val)
    )
    params.types.forEach((type) =>
      filterParams.append('document_types__id', type)
    )
    params.is_ready.forEach((val) => filterParams.append('is_ready', val))

    return filterParams
  },
  getInvoices(state) {
    return state.invoices
  },
  getAllInvoices(state) {
    return state.allInvoices
  },
  getCurrentInvoicePage(state) {
    return state.currentInvoicePage
  },
  getCurrentInvoicePages(state) {
    return state.currentInvoicePages
  },
  getInvoicePageNumber(state) {
    return state.invoicePageNumber
  },
  getStaffMessage(state){
    return state.staffMessage
  },
  getCurrentDataset(state){
    return state.currentDataSet
  },
  getInvoiceCount(state) {
    return state.invoiceCount
  },
  getLoadDataAsync(state){
    return state.loadDataAsync
  },
}

export const mutations = {
  setInvoices(state, invoices) {
    state.invoices = invoices
  },
  setAllInvoices(state, invoices) {
    state.allInvoices = invoices
  },
  setInvoiceCount(state, count) {
    state.invoiceCount = count
  },
  setCurrentInvoice(state, invoice) {
    state.currentInvoice = invoice
  },
  setCurrentInvoicePage(state, invoicePage) {
    state.currentInvoicePage = invoicePage
  },
  setCurrentInvoicePages(state, invoicePages) {
    state.currentInvoicePages = invoicePages
  },
  setInvoicePageNumber(state, pageNumber) {
    state.invoicePageNumber = pageNumber
  },
  setCurrentPage(state, page) {
    state.currentPage = page
  },
  setTypes(state, type) {
    state.types = type
  },
  setSearchText(state, text) {
    state.searchText = text
  },
  setSearchPayload(state, payload) {
    state.searchPayload = payload
  },
  setFilterActive(state, bool) {
    state.filterActive = bool
  },
  setAssociatedForms(state, associatedForms) {
    state.associatedForms = associatedForms
  },
  setDocumentIsFlagged(state, isFlagged) {
    state.documentIsFlagged = isFlagged
  },
  setNextAllowed(state, next) {
    state.nextAllowed = next
  },
  setPreviousAllowed(state, previous) {
    state.previousAllowed = previous
  },
  setFirstRecord(state, record) {
    state.firstRecord = record
  },
  setLastRecord(state, record) {
    state.lastRecord = record
  },
  setCurrentInvoiceRevenue(state, val) {
    state.currentInvoice.revenue = val
  },
  setCurrentInvoiceCurrency(state, val) {
    state.currentInvoice.revenue_currency = val
  },
  setCurrentInvoiceSupplier(state, val) {
    state.currentInvoice.supplier_id = val !== null ? val.id : null
  },
  setCurrentInvoiceOECDSector(state, val) {
    state.currentInvoice.oecd_sector = val
  },
  setCurrentInvoiceTypes(state, val) {
    state.currentInvoice.document_types = val
  },
  setCurrentInvoiceTransactionTime(state, val) {
    state.currentInvoice.transaction_time = val
  },
  setCurrentInvoiceLabels(state, val) {
    state.currentInvoice.labels = val
  },
  toggleCurrentInvoiceIsRelevant(state) {
    state.currentInvoice.is_relevant = !state.currentInvoice.is_relevant
  },
  toggleCurrentInvoiceIsSupervised(state) {
    state.currentInvoice.is_supervised = !state.currentInvoice.is_supervised
  },
  toggleInvoiceIsFlagged(state, index) {
    state.invoices[index].is_flagged = !state.invoices[index].is_flagged
  },
  setStaffMessage(state, val){
    state.staffMessage = val
  },
  setCurrentDataset(state, val){
    state.currentDataSet = val
  },
  setLoadDataAsync(state, val){
    state.loadDataAsync = val
  }
}

export const actions = {
  async TOGGLE_INVOICE_IS_FLAGGED({ state, commit, dispatch }, index) {
    /** Toggles is_flagged for the n-th value in the current result set */
    commit('toggleInvoiceIsFlagged', index)
    await dispatch('UPDATE_INVOICE', { record: state.invoices[index], key: 'is_flagged' })
  },

  async TOGGLE_CURRENT_INVOICE_IS_RELEVANT({ state, commit, dispatch }) {
    commit('toggleCurrentInvoiceIsRelevant')
    await dispatch('UPDATE_INVOICE', { record: state.currentInvoice, key: 'is_relevant' })
  },

  async TOGGLE_CURRENT_INVOICE_IS_SUPERVISED({ state, commit, dispatch }) {
    commit('toggleCurrentInvoiceIsSupervised')
    await dispatch('UPDATE_INVOICE', { record: state.currentInvoice, key: 'is_supervised' })
  },

  async GET_ALL_INVOICES({ commit, getters }) {
    // We apply an ordering to the invoices that makes it easy to process:
    // -upload_date -> invoices from newest uploads come first
    // page_no -> show invoices from the same upload in the order of their pages
    const invoices = await this.$axios.$get(
      `${process.env.BASE_V2_URL}invoices?ordering=-upload_date,first_page_no&expand=original_upload,document_types,pages`
    )
    if (invoices !== null) {
      commit('setInvoiceCount', invoices.count)
      commit('setInvoices', invoices.results)
    }
  },

  async GET_INVOICES_IN_BACKGROUND({ commit, getters }) {
    try {
      let totalResults = []
      const count = getters.getInvoiceCount
      // const currentPage = getters.getCurrentPage
      const params = getters.getFilterParams
      const pages = Math.ceil(count / 10)
      if (count > 10) {
        for (let i = 1; i <= pages; i++) {
          params.set('page', i)
          const response = await this.$axios.$get(
            `${process.env.BASE_V2_URL}invoices?ordering=-upload_date,first_page_no&expand=original_upload,document_types,pages`,{ params }
          )
          totalResults = [...totalResults, ...response.results]
          commit('setAllInvoices', totalResults)
        }
      }
    } catch (error) {
      console.error('GET /invoices error: ' + error)
      return error
    }
  },

  async GET_FILTERED_INVOICES({ commit, getters, dispatch }) {
    // We apply an ordering to the invoices that makes it easy to process:
    // -upload_date -> invoices from newest uploads come first
    // page_no -> show invoices from the same upload in the order of their pages
    try {
      const response = await this.$axios.$get(
        `${process.env.BASE_V2_URL}invoices?ordering=-upload_date,first_page_no&expand=original_upload,document_types,pages`,
        {
          params: getters.getFilterParams
        }
      )

      if (response !== null) {
        commit('setInvoiceCount', response.count)
        commit('setInvoices', response.results)
        commit('setInvoicePageNumber', 1)
        commit('setCurrentInvoice', response.results[0])
        if (response.results[0].pages !== null) {
          commit('setCurrentInvoicePage', response.results[0].pages[0])
          commit('setCurrentInvoicePages', response.results[0].pages)
        }
      }
    } catch (error) {
      console.error('GET /invoices error: ' + error)
      console.log(error.response.data , error.response.data?.detail)
      // Ideally happens when the items in the last page are removed from set filter and next page vanishes
      if(error.response.data && error.response.data?.detail === 'Invalid page.'){
        let currentPage = getters.getCurrentPage 
        currentPage = currentPage > 1 ? currentPage - 1 : 1
        commit('setCurrentPage', currentPage) // proceds to set last possible page if not sets page to 1 
        dispatch('GETINVOICE_ON_PAGE_ERROR')
      }
      return error
    }
  },

  async GET_TYPES({ commit }) {
    try {
      const response = await this.$axios.$get(
        `${process.env.BASE_V2_URL}document-types`,
        {
          params: {
            page_size: 'all',
            ordering: 'name'
          }
        }
      )
      if (response !== null) {
        commit('setTypes', response.results)
      }
    } catch (error) {
      console.log('GET Types error' + error)
      return error
    }
  },


  UPDATE_INVOICE(context, { record, key }) {
    const payload = {}
    if (Array.isArray(key)) {
      for (const i in key) {
        payload[key[i]] = record[key[i]]
      }
    } else {
      payload[key] = record[key]
    }
    return this.$axios.$patch(`${process.env.BASE_V2_URL}invoices/${record.id}`, payload)
  },

  UPDATE_INVOICE_BASE_DATA(context, { invoice, baseData }) {
    this.$axios.$patch(
      `${process.env.BASE_V2_URL}invoices/${invoice.id}`,
      {
        revenue: baseData.revenue,
        revenue_currency: baseData.revenue_currency,
        supplier_id: baseData.supplier,
        transaction_time: baseData.transaction_time,
        oecd_sector: baseData.oecd_sector
      }
    )
  },

  async SAVE_CURRENT_INVOICE({ state }) {
    try {
      await this.$axios.$put(
        `${process.env.BASE_V2_URL}invoices/${state.currentInvoice.id}`,
        state.currentInvoice
      )
    } catch (error) {
      console.log('GET Types error' + error)
      return error
    }
  },

  async GET_INVOICE_BY_ID({ state, getters, commit }, id) {
    // if the invoice is in the current page of invoices, we just use that
    const index = state.invoices
      .map((doc) => {
        return doc.id
      })
      .indexOf(id)
    if (index >= 0) {
      const currentPage = getters.currentPage
      const invoice = state.invoices[index]
      commit('setCurrentInvoice', invoice)
      commit('setCurrentInvoicePage', invoice.pages[0])
      commit('setCurrentInvoicePages', invoice.pages)
      commit('setInvoicePageNumber', 1)
      if (
        index < state.invoices.length - 1 ||
        currentPage !== Math.ceil(state.invoiceCount / 10)
      )
        commit('setNextAllowed', true)
      else commit('setNextAllowed', false)
      if (index > 0 || currentPage > 0) commit('setPreviousAllowed', true)
      else commit('setPreviousAllowed', false)
      return
    }
    // invoice was not in current page of invoices, so we need to get it from the API
    const response = await this.$axios.$get(
      `${process.env.BASE_V2_URL}invoices/${id}?expand=original_upload,document_types,pages`
    )
    if (response !== null) {
      commit('setInvoices', [response])
      commit('setCurrentInvoice', response)
      commit('setCurrentInvoicePage', response.pages[0])
      commit('setCurrentInvoicePages', response.pages)
      commit('setInvoicePageNumber', 1)
      commit('setCurrentPage', 1)
      commit('setNextAllowed', false)
      commit('setPreviousAllowed', false)
    }
  },

  SPLIT_CURRENT_INVOICE({ state, getters, commit, dispatch }) {
    this.$axios
      .$post(`${process.env.BASE_V2_URL}invoice-pages/${state.currentInvoicePage.id}/invoice?expand=original_upload,document_types,pages`)
      .then(response => {
        const invoices = this._vm._.cloneDeep(getters.getInvoices)
        const index = invoices
          .map((doc) => { return doc.id })
          .indexOf(state.currentInvoice.id)
        const oldInvoice = invoices[index]
        oldInvoice.pages.splice(state.invoicePageNumber - 1)
        invoices.splice(index, 1, oldInvoice, response)
        commit('setInvoices', invoices)
        dispatch('GET_NEXT_INVOICE')
        this._vm.$bvToast.toast(`The invoice was split successfully.`, { variant: 'success', title: 'Success' })
      })
      .catch(response => {
        this._vm.$bvToast.toast(
          `Invoices cannot be split on the first page.`,
          { variant: 'danger', title: 'Invoice could not be split' }
        )
      })
  },

  async UNSPLIT_CURRENT_INVOICE({ state, getters, commit, dispatch }) {
    const oldInvoice = getters.getCurrentInvoice
    await this.$axios
      .$delete(`${process.env.BASE_V2_URL}invoice-pages/${oldInvoice.pages[0].id}/invoice?expand=original_upload,document_types,pages`)
      .then(response => {
        const invoices = this._vm._.cloneDeep(getters.getInvoices)
        const index = invoices
          .map((doc) => { return doc.id })
          .indexOf(oldInvoice.id)
        invoices.splice(index, 1)
        const newIndex = invoices
          .map((doc) => { return doc.id })
          .indexOf(response.id)
        if (newIndex < 0) {
          invoices.splice(index, 0, response)
        }
        commit('setInvoices', invoices)
        commit('setCurrentInvoice', response)
        commit('setCurrentInvoicePage', response.pages[0])
        commit('setCurrentInvoicePages', response.pages)
        commit('setInvoicePageNumber', 1)
        this._vm.$bvToast.toast(`The split invoice was merged successfully.`, { variant: 'success', title: 'Success' })
      })
      .catch(response => {
        this._vm.$bvToast.toast(
          `Invoices can only be unsplit, if they have been split from another invoice.`,
          { variant: 'danger', title: 'Invoice could not be unsplit' }
        )
      })
  },

  GETINVOICE_ON_PAGE_ERROR({ commit, dispatch }){
    let invoice = state.currentInvoice
    dispatch('GET_FILTERED_INVOICES').then(() => {
      invoice = state.invoices[0]
      commit('setCurrentInvoice', invoice)
      commit('setCurrentInvoicePage', invoice.pages[0])
      commit('setCurrentInvoicePages', invoice.pages)
      commit('setInvoicePageNumber', 1)
    })
  },

  GET_NEXT_INVOICE({ state, getters, commit, dispatch }) {
    const currentPage = getters.getCurrentPage
    const arrayLength = state.invoices.length
    let invoice = state.currentInvoice
    const index = state.invoices
      .map((doc) => { return doc.id })
      .indexOf(invoice.id)
    if (index < arrayLength - 1) {
      invoice = state.invoices[index + 1]
      commit('setNextAllowed', true)
      commit('setPreviousAllowed', true)
      if (
        index + 1 === arrayLength - 1 &&
        currentPage === Math.ceil(state.invoiceCount / 10)
      ) {
        commit('setNextAllowed', false)
      }
    } else {
      commit('setCurrentPage', currentPage + 1)
      const allInvoices = state.allInvoices.length
      if(state.loadDataAsync && allInvoices > (currentPage) * 10){
        invoice = state.allInvoices[currentPage * 10]
        commit('setInvoices', state.allInvoices.slice(currentPage * 10, ((currentPage + 1) * 10)))
      }else{
        dispatch('GET_FILTERED_INVOICES').then(() => {
          invoice = state.invoices[0]
        })
      }
    }
    commit('setCurrentInvoice', invoice)
    commit('setCurrentInvoicePage', invoice.pages[0])
    commit('setCurrentInvoicePages', invoice.pages)
    commit('setInvoicePageNumber', 1)
  },

  GET_PREVIOUS_INVOICE({ state, getters, commit, dispatch }) {
    const allInvoices = state.allInvoices.length
    const currentPage = getters.getCurrentPage
    let invoice = state.currentInvoice
    const index = state.invoices
      .map((doc) => {
        return doc.id
      })
      .indexOf(invoice.id)
    if (index > 0) {
      invoice = state.invoices[index - 1]
      commit('setNextAllowed', true)
      commit('setPreviousAllowed', true)
      if (index - 1 === 0 && currentPage === 1) {
        commit('setPreviousAllowed', false)
      }
    } else {
      commit('setCurrentPage', currentPage - 1)
      if(currentPage > 1 && state.loadDataAsync && allInvoices){
        commit('setInvoices', state.allInvoices.slice(((currentPage - 1) * 10), currentPage * 10))
        invoice = state.allInvoices[((currentPage - 1) * 10) -1]
      }else{
        dispatch('GET_FILTERED_INVOICES').then(() => {
          invoice = state.invoices[9]
        })
      }
      
    }
    commit('setCurrentInvoice', invoice)
    commit('setCurrentInvoicePage', invoice.pages[0])
    commit('setCurrentInvoicePages', invoice.pages)
    commit('setInvoicePageNumber', 1)
  },

  async GET_EXACT_INVOICE_PAGE({ state, commit }, pageData) {
    try {
      const pages = state.currentInvoicePages
      if (pages.length >= pageData.page) {
        const exactPage = await this.$axios.$get(
          `${process.env.BASE_V2_URL}invoice-pages/${pages[parseInt(pageData.page)-1].id}`
        )
        commit('setCurrentInvoicePage', exactPage)
        commit('setInvoicePageNumber', parseInt(state.invoicePageNumber))
      }
    } catch (error) {
      console.error('GET Types error' + error.response)
      return error.response
    }
  },

  async GET_STAFF_MESSAGE({ state, commit }) {
    try {
      if (state.invoices.length > 0) {
        const data = await this.$axios.$get(
          `datasets/${state.invoices[0].dataset_id}`
        )
        commit('setStaffMessage', data.staff_comment)
        commit('setCurrentDataset', data)
      }
    } catch (error) {
      console.log('GET Types error' + error.response)
      return error.response
    }
  },

 async UPDATE_STAFF_COMMENT({  state, commit }, staffMessage) {
    let dataTemp = {}
    dataTemp = Object.assign(dataTemp, state.currentDataSet)
    dataTemp.staff_comment = staffMessage.message
    await this.$axios.$patch(`datasets/${state.invoices[0].dataset_id}`, {'staff_comment': staffMessage.message})
  }
}
