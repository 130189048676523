
export default {
  name: 'Header',
  // props: {
  //   height: {
  //     type: String,
  //     required: true,
  //   },
  // },
  data() {
    return {
      // spenokiLogo: SpenokiLogo,
      // user: this.$auth.user,
      // switch: null,
      // isRequired: true,
      // dates: ['2019-01-01', '2019-12-31'],
    }
  },

  computed: {
    // tenant() {
    //   return this.$store.state.tenant.myTenant
    // },
    // ...mapGetters({
    //   startDate: 'indicators/getStartDate',
    //   endDate: 'indicators/getEndDate',
    //   datasetId: 'indicators/getDataset',
    //   isDataSet: 'tenant/getIsDataSet',
    //   userError: 'tenant/getUserError',
    // }),
  },
  created() {
    this.$nuxt.$on('switch-screen', (val) => {
      this.switch = val
    })
  },
  // mounted() {
  //   this.$store.commit('indicators/setIsLoading', true)
  //   this.$nuxt.$emit('is-required', false)
  // },
  beforeDestroy() {
    this.$nuxt.$off('switch-screen')
  },

  methods: {
    // userLogout() {
    //   try {
    //     // eslint-disable-next-line no-console
    //     console.log('Logging out')
    //     this.$auth.logout().then(() => this.$message.success('Logged Out!'))
    //   } catch (err) {
    //     // eslint-disable-next-line no-console
    //     console.log(err)
    //   }
    // },
    // accountDropdownClicked({ key }) {
    //   // eslint-disable-next-line no-console
    //   console.log(`[Account Dropdown] : Clicked on item ${key}`)
    //   switch (key) {
    //     case 'user':
    //       this.$router.push('me')
    //       break
    //     case 'settings':
    //       // TODO: fix issue with wrong viewport width rendering in /organizations/new
    //       this.$router.push('settings')
    //       break
    //     case 'logout':
    //       this.userLogout()
    //       break
    //     default:
    //       // this.$router.push({ path: `/organizations/${key}` })
    //       break
    //   }
    // },
    // async updateDate() {
    //   await this.$store.commit('indicators/setStartDate', this.dates[0])
    //   await this.$store.commit('indicators/setEndDate', this.dates[1])
    // },
    // async fetchData() {
    //   await this.updateDate().then(() => {
    //     if (this.datasetId !== null) {
    //       this.$store.dispatch('indicators/GET_DASHBOARD_DATA')
    //     }
    //   })
    // },
  }
}
